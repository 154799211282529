/**
 * Trap & cycle TAB key focus inside an element, e.g. a modal.
 *
 * This is a very barebones implementation for the 80% use case
 * (e.g. it will break when non-zero tab indexes are present)
 */

/**
 * @typedef {{
 *   first: Element
 *   last: Element
 * }} TrapperBoundaries
 *
 * @typedef {{
 *   restoreFocus: boolean|Element
 *   staticFocusables: boolean
 * }} TrapperOptions
 *
 * @typedef {{
 *   element: Element
 *   focusedElement?: Element
 *   focusableElements?: TrapperBoundaries
 * }} TrapperStackItem
 */

/**
 * The stack of trapped elements
 *
 * @type {TrapperStackItem[]}
 */
let stack = []

let focusableSelectors = [
  '[href]:not(link)',
  'button:not(:disabled)',
  'input:not(:disabled)',
  'select:not(:disabled)',
  'textarea:not(:disabled)',
  '[tabindex]:not([tabindex="-1"])',
]

let focusableSelector = focusableSelectors.join(', ')

/**
 * @param {Element} container
 * @returns {TrapperBoundaries|undefined}
 */
function getFocusableElements(container) {
  let focusableElements = $(container)
    .find(focusableSelector)
    .filter(':visible')
    .get()
  if (focusableElements.length === 0) {
    return undefined
  }

  return {
    first: focusableElements[0],
    last: focusableElements[focusableElements.length - 1],
  }
}

function tabKeyListener(event) {
  if (event.key !== 'Tab') {
    return
  }

  let { element, focusableElements } = stack[stack.length - 1]

  focusableElements = focusableElements ?? getFocusableElements(element)
  if (!focusableElements) {
    return
  }

  if (!element.contains(document.activeElement)) {
    event.preventDefault()
    event.stopPropagation()

    if (event.shiftKey) {
      focusableElements.last.focus()
    } else {
      focusableElements.first.focus()
    }
  } else if (
    document.activeElement === focusableElements.first &&
    event.shiftKey
  ) {
    event.preventDefault()
    event.stopPropagation()

    focusableElements.last.focus()
  } else if (
    document.activeElement === focusableElements.last &&
    !event.shiftKey
  ) {
    event.preventDefault()
    event.stopPropagation()

    focusableElements.first.focus()
  }
}

/**
 * @param {Element} element The container element to trap focus inside
 * @param {Partial<TrapperOptions>} options
 */
export function trapper(
  element,
  { restoreFocus = true, staticFocusables = false } = {},
) {
  if (!(element instanceof Element) || !document.body.contains(element)) {
    console.warn(
      'Element %o cannot be trapped as it is not found in the DOM',
      element,
    )
    return
  }

  /**
   * @type {TrapperStackItem}
   */
  const stackItem = { element }

  // Pre-determine first and last focusable ancestor
  if (staticFocusables) {
    let focusableElements = getFocusableElements(element)
    if (!focusableElements) {
      return
    }
    stackItem.focusableElements = focusableElements
  }

  if (stack.length === 0) {
    document.addEventListener('keydown', tabKeyListener)
  }

  stack.push(stackItem)

  let preFocusedElement
  // Restore focus after untrapping
  if (restoreFocus === true) {
    preFocusedElement = document.activeElement
  } else if (restoreFocus instanceof Element) {
    preFocusedElement = restoreFocus
  }

  return () => {
    if (stack.length === 0) {
      return
    }

    stack.pop()

    if (preFocusedElement) {
      preFocusedElement.focus()
    }

    if (stack.length === 0) {
      document.removeEventListener('keydown', tabKeyListener)
    }
  }
}
