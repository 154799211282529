import './modules/jquery.global-dollar.js'
import './modules/search.js'
import './modules/menu.js'

$(function () {
  /*******************************
   * resizeEnd Event (https://stackoverflow.com/a/12692647/12404191)
   * Example usage:
   *
   * $(window).bind('resizeEnd', function() {
   * 		//do something, window hasn't changed size in 500ms
   * });
   ******************************/

  $(window).resize(function () {
    if (this.resizeEndTimeout) clearTimeout(this.resizeEndTimeout)
    this.resizeEndTimeout = setTimeout(function () {
      $(window).trigger('resizeEnd')
    }, 500)
  })

  /*******************************
   *  --vh CSS variable
   *  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   ******************************/

  function calculateVh() {
    var vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', vh + 'px')
  }

  calculateVh()

  $(window).on('resizeEnd', function () {
    calculateVh()
  })

  /*******************************
   *  --scrollbar-width CSS variable
   ******************************/

  function calculateScrollbarWidth() {
    var scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      scrollbarWidth + 'px',
    )
  }

  calculateScrollbarWidth()

  $(window).on('resizeEnd', function () {
    calculateScrollbarWidth()
  })

  /*******************************
   * Sticky Header
   ******************************/

  let header = $('header.main')
  let stickyClass = 'sticky'

  function checkSticky () {
    if (window.pageYOffset > 0) {
      header.addClass(stickyClass)
    } else {
      header.removeClass(stickyClass)
    }
  }

  function initStickyHeader () {
    checkSticky()
    document.addEventListener('scroll', function(e) {
      checkSticky()
    }, { passive: true })
  }

  initStickyHeader()


})
